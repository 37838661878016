<div fxLayout="column" fxLayoutAlign="start">
  <div class="page-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
    <div class="mat-display-1 page-content-title">
      Bedrijfsgegevens <span class="version-label">({{ environment.appVersion }})</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <ng-container *ngIf="authService.hasSuperadmin() | async">
        <button (click)="save()" [disabled]="saving" type="submit" mat-raised-button color="primary">Opslaan</button>
      </ng-container>
    </div>
  </div>
</div>

<form [formGroup]="form" autocomplete="off" (onSubmit)="save()">
  <div class="page-content" fxLayout="column" fxLayoutGap="16px">
    <mat-card fxFlex *appSkeletonLoader="{ loading: loading, amountOfRows: 4, height: '32px', minWidth:'20%', maxWidth: '90%', inMatCard: true}">
      <mat-card-title>Algemeen</mat-card-title>
      <mat-card-content fxLayout="row wrap" fxLayoutGap="16px">
        <mat-form-field fxFlex fxFlex.lt-md="calc(50%-16px)">
          <mat-label>NIS-code</mat-label>
          <input matInput formControlName="Niscode" placeholder="NIS-code" required/>
        </mat-form-field>
        <mat-form-field fxFlex fxFlex.lt-md="calc(50%-16px)">
          <mat-label>Benaming</mat-label>
          <input matInput formControlName="Name" placeholder="Benaming" required/>
        </mat-form-field>
        <mat-form-field fxFlex fxFlex.lt-md="calc(50%-16px)">
          <mat-label>Dienst/cel</mat-label>
          <input matInput formControlName="ServiceCell" placeholder="Dienst/cel" required/>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxFlex fxFlex.lt-md="calc(50%-16px)">
          <div fxFlex="50%">
            <mat-slide-toggle formControlName="HasInventory" color="primary">Beheert inventaris</mat-slide-toggle>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card fxFlex *appSkeletonLoader="{ loading: loading, amountOfRows: 4, height: '32px', minWidth:'20%', maxWidth: '90%', inMatCard: true}">
      <mat-card-title>Adres</mat-card-title>
      <mat-card-content fxLayout="row wrap" fxLayoutGap="16px">
        <mat-form-field fxFlex fxFlex.lt-md="calc(50%-16px)">
          <mat-label>Straat</mat-label>
          <input matInput formControlName="Street" placeholder="Straat" required/>
        </mat-form-field>
        <mat-form-field fxFlex fxFlex.lt-md="calc(50%-16px)">
          <mat-label>Huisnummer</mat-label>
          <input matInput formControlName="HouseNumber" placeholder="Huisnummer" required/>
        </mat-form-field>
        <mat-form-field fxFlex fxFlex.lt-md="calc(50%-16px)">
          <mat-label>Postcode</mat-label>
          <input matInput formControlName="PostalCode" placeholder="Postcode" required/>
        </mat-form-field>
        <mat-form-field fxFlex fxFlex.lt-md="calc(50%-16px)">
          <mat-label>Gemeente</mat-label>
          <input matInput formControlName="City" placeholder="Gemeente" />
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card fxFlex *appSkeletonLoader="{ loading: loading, amountOfRows: 4, height: '32px', minWidth:'20%', maxWidth: '90%', inMatCard: true}">
      <mat-card-title>Contact</mat-card-title>
      <mat-card-content fxLayout="row wrap" fxLayoutGap="16px">
        <mat-form-field fxFlex fxFlex.lt-md="100%">
          <mat-label>Telefoon</mat-label>
          <input matInput formControlName="Telephone" placeholder="Telefoon" />
        </mat-form-field>
        <mat-form-field fxFlex fxFlex.lt-md="100%">
          <mat-label>Fax</mat-label>
          <input matInput formControlName="Fax" placeholder="Fax" />
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card fxFlex *appSkeletonLoader="{ loading: loading, amountOfRows: 4, height: '32px', minWidth:'20%', maxWidth: '90%', inMatCard: true}">
      <mat-card-title>Velden</mat-card-title>
      <mat-card-content fxLayout="row" fxLayoutGap="16px">
        <div fxLayout="column" fxFlex fxFlex.lt-md="100%">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxFlex="50%">
              <mat-checkbox #input matInput formControlName="CadastralSurfaceEnabled" placeholder="Value">Kadastrale oppervlakte</mat-checkbox>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxFlex="50%">
              <mat-checkbox #input matInput formControlName="TotalSurfaceEnabled" placeholder="Value">Totale oppverlakte</mat-checkbox>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxFlex="50%">
              <mat-checkbox #input matInput formControlName="FloorSurfaceEnabled" placeholder="Value">Vloeroppervlakte</mat-checkbox>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxFlex="50%">
              <mat-checkbox #input matInput formControlName="BuildingLayersEnabled" placeholder="Value">Aantal bouwlagen</mat-checkbox>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxFlex="50%">
              <mat-checkbox #input matInput formControlName="SideLengthEnabled" placeholder="Value">Gevellengte (straat)</mat-checkbox>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxFlex="50%">
              <mat-checkbox #input matInput formControlName="TotalStreetsOnSideEnabled" placeholder="Value">Aantal straten aan gevel</mat-checkbox>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex fxFlex.lt-md="100%">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxFlex="50%">
              <mat-checkbox #input matInput formControlName="LocationTypeEnabled" placeholder="Value">Locatie</mat-checkbox>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxFlex="50%">
              <mat-checkbox #input matInput formControlName="DistrictEnabled" placeholder="Value">Wijk</mat-checkbox>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</form>

